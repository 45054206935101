import Header from './header';
import Footer from './footer';
import { FC, ReactNode } from 'react';

interface Props {
  nav: boolean;
  children: ReactNode;
}

const Layout: FC<Props> = ({ children, nav }) => {
  return (
    <>
      {nav === true ? <Header /> : ''}
      <main className="min-h-screen bg-gradient-to-b from-transparent  to-slate-50 px-1 md:px-2">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
