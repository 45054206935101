import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
  return (
    <footer className="bg-neutral-900 text-white pt-10">
      <div className="container mx-auto">
        <div className="flex flex-col gap-6 pb-10">
          <div className="flex justify-center relative">
            <span className="absolute block h-px bg-white opacity-10 bottom-1/2 left-0 right-0"></span>
            <div className="bg-neutral-900 px-6 relative">
              <StaticImage
                src={'../../src/images/logos/dba_logo.png'}
                alt="placeholder"
                className="w-20 aspect-square border border-white rounded-full "
                objectFit="contain"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="flex lg:flex-row  flex-col justify-center items-center gap-4 text-center">
            <p className="text-body-sm md:text-body-md font-medium text-neutral-100">
              Copyright © 2023, DBA Holdings LLC.
            </p>
            <Link to="/privacy-policy" className="text-primary-100">
              <p className="text-body-sm md:text-body-md  text-neutral-400">
                Privacy Policy
              </p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
