import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  return (
    <header className="relative mt-4">
      <nav className="container mx-auto">
        <div className="flex py-6 justify-start items-center gap-5">
          <Link to="/">
            <StaticImage
              src={'../../src/images/logos/dba_logo.png'}
              alt="placeholder"
              className="w-24 aspect-square"
              objectFit="contain"
              placeholder="blurred"
            />
          </Link>
          <h3 className="text-display-xl font-blinker font-bold text-black">
            DBA Holdings
          </h3>
        </div>
      </nav>
    </header>
  );
};

export default Header;
